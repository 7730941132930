import * as React from 'react'
// @ts-ignore
import styled from 'styled-components'
import Layout from '../components/Layout/Layout'
import { Color } from '@/theme/colors'
import IconCatCircle from '@/components/IconCatCircle/IconCatCircle'
// @ts-ignore
import Image1 from '@/assets/images/konsultacje-behawioralne-kotow.png'
import {
  Bolder,
  Header1,
  Header2,
  Header3,
  Paragraph,
} from '@/theme/Typography.styled'
import { Container } from '@/theme/Grid.styled'
import Banner1 from '@/components/Banner1/Banner1'
import DownloadFormBanner from '@/components/DownloadFormBanner/DownloadFormBanner'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Color.Pink_1};
  text-align: center;

  img {
    width: 600px;
    max-width: 90%;
    margin: 30px auto;
  }

  @media (max-width: 991px) {
    svg {
      margin-bottom: 15px;
    }
  }
`

const Description = styled.div`
  padding: 50px 0;

  svg {
    margin: 30px 0;
  }

  @media (max-width: 991px) {
    padding: 0;

    svg {
      margin: 15px 0;
    }
  }
`

const Pricing = styled.div`
  display: grid;
  grid-row-gap: 30px;
  justify-content: center;
  align-content: center;
  padding: 50px 0 70px;

  @media (max-width: 991px) {
    grid-row-gap: 15px;
    padding: 30px 0;
  }
`

const Head = () => (
  <>
    <title>Pet School - Konsultacje behawioralne kotów</title>
  </>
)

const CatBehavioralConsultationsPage = () => {
  return (
    <>
      <Head />
      <Layout>
        <Wrapper>
          <Container>
            <img
              alt="Pet School - konsultacje behawioralne kotów"
              src={Image1}
            />
            <Description>
              <Header2 marginBottom={39}>
                <Bolder>Konsultacja behawioralna kotów</Bolder>
              </Header2>

              <Paragraph>
                Na konsultacji indywidualnej, która odbywa się u klienta,
                analizuję dokładnie problem, zbieram wywiad oraz przyglądam się
                komunikacji i relacji pomiędzy kotem a domownikami. Staram się
                dobierać metody pracy, które będą w danym przypadku
                najskuteczniejsze.
              </Paragraph>

              <Paragraph>
                Zajmuje się między innymi takimi problemami jak: nadmierna
                lękliwość i fobie, nadpobudliwość, agresja do ludzi i zwierząt.
                Również pomagam wprowadzić kota do psa oraz drugiego kota do
                domu.
              </Paragraph>

              <IconCatCircle />

              <Paragraph>
                Oferuję doradztwo w wyborze rasy, hodowli oraz adopcji oraz
                pierwszych dni w nowym domu zarówno kociaka jak i kotów
                dorosłych.
              </Paragraph>

              <Paragraph>
                Podczas konsultacji dotyczących wychowania kociaka opowiadam
                między innymi o etapach rozwoju kota, specyfice rasy, kocich
                potrzebach i jak prawidłowo je zaspokajać, jak przeprowadzić
                prawidłową socjalizację i habituację, o komunikacji kotów oraz
                najważniejsze akcesoria w domu.
              </Paragraph>
            </Description>
          </Container>

          <Banner1>
            <Header3>
              <Bolder>
                Zawsze po konsultacji, wysyłam klientowi zalecenia wraz z planem
                pracy i materiałami z zakresu komunikacji kotów.
              </Bolder>
            </Header3>
          </Banner1>

          <Container>
            <Pricing>
              <div>
                <Paragraph>
                  Koszt i czas trwania pierwszej konsultacji:
                </Paragraph>
                <Header1>
                  <Bolder>200 zł / 90 minut</Bolder>
                </Header1>
              </div>

              <div>
                <Paragraph>
                  Koszt i czas trwania każdej kolejnej konsultacji:
                </Paragraph>
                <Header1>
                  <Bolder>120 zł / 60 minut</Bolder>
                </Header1>
              </div>

              <div>
                <Paragraph>
                  *Za każde rozpoczęte <strong>30 minut</strong> doliczane jest
                  dodatkowo <strong>30 zł</strong>.
                </Paragraph>
              </div>

              <Paragraph>
                Częstotliwość:{` `}
                <Bolder>
                  Co dwa tygodnie lub do ustalenia indywidualnie z klientem
                </Bolder>
              </Paragraph>
            </Pricing>
          </Container>

          <DownloadFormBanner cat={true} />
        </Wrapper>
      </Layout>
    </>
  )
}

export default CatBehavioralConsultationsPage
